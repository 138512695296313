import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

// Images
import Post_1 from "~images/blog/img/eatblog1.png";
import Post_2 from "~images/blog/img/blogpost2.png";
import Post_3 from "~images/blog/img/Blog-Image-3.jpg";

const Blog = (props) => {
    return (
        <section id="blog" className={props.className}>
			
			{/* <!-- Container --> */}
			<Container>
				
				{/* <!-- Section title --> */}
				<Row className="justify-content-center">
					<Col className="col-12 col-md-10 col-lg-6">
						
						<div className="section-title text-center">
							<h3>Latest Blog Posts</h3>
							<p>Check out our latest blog posts to follow up with latest trends and get an idea about homely food and more!</p>
                        </div>
						
					</Col>
				</Row>

				<Row className="blog-home">
					
					{/* <!-- Post 1 --> */}
					<Col className="col-12 col-lg-4 res-margin">
                        
						<div className="blog-col">
                            
							<p>
								<a href="/blog-post">
									<img src={Post_1} className="blog-img" alt="" />
								</a>
								<span className="blog-category">Zahra Ayaz</span>
							</p>
                            
							<div className="blog-wrapper">								
								<div className="blog-text">
                                    
									{/* <p className="blog-about">
                                        <span>Matthew Johns</span>
                                        <span>January 14, 2022</span>
                                    </p> */}
                                    
									<h4>
                                        <a href="/blog-post">5 simple steps to keep your home kitchen clean</a>
                                    </h4>
                                    
                                    <p>
									A clean home is a happy home. Every home chef need to ensure the cleaning and sanitisation of their kitchen.
										<a href="/blog-post" className="btn-read-more">Read More</a>
									</p>
                                    
								</div>
							</div>
                            
						</div>
                        
					</Col>
					
					{/* <!-- Post 2 --> */}
					<Col className="col-12 col-lg-4 res-margin">
                        
						<div className="blog-col">
                            
							<p>
								<a href="/blog-post">
									<img src={Post_2} className="blog-img" alt="" />
								</a>
								<span className="blog-category">Menu Structure</span>
							</p>
                            
							<div className="blog-wrapper">
								<div className="blog-text">
                                    
									{/* <p className="blog-about">
                                        <span>Alex Wesly</span>
                                        <span>December 30, 2021</span>
                                    </p> */}
                                    
									<h4>
                                        <a href="/blog-post"> Benefits of eating homely food</a>
                                    </h4>
                                    
                                    <p>
									It’s not always easy to prepare all your meals at home and for most people who love to cook sometimes there isn't enough time.
										<a href="/blog-post-site" className="btn-read-more">Read More</a>
									</p>
                                    
								</div>
							</div>
                            
						</div>
                        
					</Col>	
					
					{/* <!-- Post 3 --> */}
					<Col className="col-12 col-lg-4">
                        
						<div className="blog-col">
                            
							<p>
								<a href="/blog-post">
									<img src={Post_3} className="blog-img" alt="" />
								</a>
								<span className="blog-category">Homely Food</span>
							</p>
                            
							<div className="blog-wrapper">
								<div className="blog-text">
                                    
									{/* <p className="blog-about">
                                        <span>Richard Jackson</span>
                                        <span>July 12, 2021</span>
                                    </p> */}
                                    
									<h4>
                                        <a href="/blog-post">Benefits of eating healthy homely food</a>
                                    </h4>
                                    
                                    <p>
										Cooking at home gives an opportunity to eat the foods you love exactly how you enjoy eating them! Simply home made food can boost your immune system.
										<a href="/blog-post" className="btn-read-more">Read More</a>
									</p>
                                    
								</div>
							</div>
                            
						</div>
                        
					</Col>
					
				</Row>
				
			</Container>
			
		</section>
    );
}

export default Blog;