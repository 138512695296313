import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import Howitworks from "~images/works/1.png";
import Howitworks1 from "~images/works/2.png";
import Howitworks2 from "~images/works/3.png";

const Services = (props) => {
    return (
        <section id="services" className={props.className}>
			
			{/* <!-- Container --> */}
			<Container>
				
				{/* <!-- Section title --> */}
				<Row className="justify-content-center">
					<Col className="col-12 col-md-10 col-lg-6">
						
						<div className="section-title text-center">
							<h3>How It Works?</h3>
							<p>Process of EatCo home <br/>Place order {'>'} Order acceptance {'>'} Fast delivery </p>
						</div>
						
					</Col>
				</Row>
			
				<Row>
					
					{/* <!-- Service 1 --> */}
					<Col className="col-12 col-lg-4 res-margin wow fadeInUp" data-wow-offset="10" data-wow-duration="1s" data-wow-delay="0">
						<div className={props.serviceClassName}>
							
							<div className=" text-center">
							<img src={Howitworks} alt="" />
							</div>
							
							<h5>Place Order</h5>
							<p>You can place an order by Login to EatCo app and browse multiple dishes, select the payment option to confirm your order.</p>
							
						</div>
					</Col>

					{/* <!-- Service 2 --> */}
					<Col className="col-12 col-lg-4 res-margin wow fadeInUp" data-wow-offset="10" data-wow-duration="1s" data-wow-delay="0.3s">
						<div className={props.serviceClassName}>
							
						<div className=" text-center">
							<img src={Howitworks1} alt="" />
							</div>
							
							<h5>Order Acceptance</h5>
							<p>The nearby chef will be notified and once they accept the order, the preparation of food will begin.						</p>
						
						</div>
					</Col>

					{/* <!-- Service 3 --> */}
					<Col className="col-12 col-lg-4 res-margin wow fadeInUp" data-wow-offset="10" data-wow-duration="1s" data-wow-delay="0.6s">
						<div className={props.serviceClassName}>
							
						<div className=" text-center">
							<img src={Howitworks2} alt="" />
							</div>
							
							<h5>Fast delivery </h5>
							<p>The delivery rider will pick up your order and drop it at your given location.											 </p>
						
						</div>
					</Col>
					
				</Row>
				
			</Container>
			
		</section>
    );
}

export default Services;