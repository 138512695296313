import React from 'react';
import { Container, Row, Col, Accordion } from 'react-bootstrap';

const Support = (props) => {
    return (
        <section id="support" className={props.className}>
			
			{/* <!-- Container --> */}
			<Container>
				
				{/* <!-- Section title --> */}
				<Row className="justify-content-center">
					<Col className="col-12 col-md-10 col-lg-6">
						
						<div className="section-title text-center">
							<h3>Frequently Asked Questions</h3>
							<p>Get all your answers here!</p>
						</div>
						
					</Col>
				</Row>
				
				<Row>					
					<Col className="col-12 col-lg-10 offset-lg-1">
						
						{/* <!-- FAQ accordion --> */}
						<Accordion defaultActiveKey="0" flush>
							
							{/* <!-- Question 1 --> */}
							<Accordion.Item eventKey="0">

								<Accordion.Header as="h5">
								What type of food can i cook? 			        	
								</Accordion.Header>

								<Accordion.Body>
									<p>
									At EatCo, we realise that each cuisine has story to tell and that’s why we will never put any restrictions in what will you be cooking.
									</p>
								</Accordion.Body>
								
							</Accordion.Item>
								
							{/* <!-- Question 2 --> */}
							<Accordion.Item eventKey="1">

								<Accordion.Header as="h5">
								How do I get paid?		        	
								</Accordion.Header>

								<Accordion.Body>
									<p>
									We will collect the payment on your behalf and we will then transfer the money to you via a bank transfer.
									</p>
								</Accordion.Body>
								
							</Accordion.Item>

							{/* <!-- Question 3 --> */}
							<Accordion.Item eventKey="2">

								<Accordion.Header as="h5">
								Can I set the price of my dishes?			        	
								</Accordion.Header>

								<Accordion.Body>
									<p>
									Of course you can! Our app provides you with full flexibility as you can set the price of your delicious home cooked dishes.
									</p>
								</Accordion.Body>
								
							</Accordion.Item>
							
							{/* <!-- Question 4 --> */}
							<Accordion.Item eventKey="3">

								<Accordion.Header as="h5">
								How do I know my order status?		        	
								</Accordion.Header>

								<Accordion.Body>
									<p>
									You will receive notification through app regarding your order status from the time you place the order to the time it’s delivered to you.
									</p>
								</Accordion.Body>
								
							</Accordion.Item>
							
							{/* <!-- Question 5 --> */}
							<Accordion.Item eventKey="4">

								<Accordion.Header as="h5">
								How do I pay?	        	
								</Accordion.Header>

								<Accordion.Body>
									<p>
									you can pay using online debit card or credit card, our secure payment provides to ensure your information is always safe.
									</p>
								</Accordion.Body>
								
							</Accordion.Item>

							{/* <!-- Question 6 --> */}
							<Accordion.Item eventKey="3">

								<Accordion.Header as="h5">
								I need to cancel or change the order. How can I do that?	        	
								</Accordion.Header>

								<Accordion.Body>
									<p>
									please contact helpline number provided in the App. We can let the kitchen know before it starts preparing your order. Once the order goes in the process, it can not be changed. With regards to any refund of a payment you have made online, please contact EatCo food delivery app.
									</p>
								</Accordion.Body>
								
							</Accordion.Item>
							
						</Accordion>
						
					</Col>					
				</Row>
				
				<div className="empty-30"></div>
				
				<Row>					
					<Col className="col-12">
						<p className="text-center mb-0">Still have a question? <a href="#contact"><strong>Ask your question here</strong></a></p>
					</Col>
				</Row>
				
			</Container>
			
		</section>
    );
}

export default Support;