import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Pagination } from 'swiper';
import { SRLWrapper } from 'simple-react-lightbox';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/pagination';

// Images
import Screenshot_1 from "~images/screenshots/s1.jpg";
import Screenshot_2 from "~images/screenshots/s2.jpg";
import Screenshot_3 from "~images/screenshots/s3.jpg";
import Screenshot_4 from "~images/screenshots/s4.jpg";
import Screenshot_5 from "~images/screenshots/s5.jpg";
import Screenshot_6 from "~images/screenshots/s6.jpg";
import Screenshot_7 from "~images/screenshots/s7.jpg";
import Screenshot_8 from "~images/screenshots/s8.jpg";
import Screenshot_9 from "~images/screenshots/s9.jpg";
import Screenshot_10 from "~images/screenshots/s10.jpg";
import Screenshot_11 from "~images/screenshots/s11.jpg";
import Screenshot_12 from "~images/screenshots/s12.jpg";
import Screenshot_13 from "~images/screenshots/s13.jpg";
import Screenshot_14 from "~images/screenshots/s14.jpg";
import Screenshot_15 from "~images/screenshots/s15.jpg";
import Screenshot_16 from "~images/screenshots/s16.jpg";
import Screenshot_17 from "~images/screenshots/s17.jpg";
import Screenshot_18 from "~images/screenshots/s18.jpg";
import Screenshot_19 from "~images/screenshots/s19.jpg";
import Screenshot_20 from "~images/screenshots/s20.jpg";
import Screenshot_21 from "~images/screenshots/s21.jpg";
import Screenshot_22 from "~images/screenshots/s22.jpg";
import Screenshot_23 from "~images/screenshots/s23.jpg";
import Screenshot_24 from "~images/screenshots/s24.jpg";
import Screenshot_25 from "~images/screenshots/s25.jpg";
import Screenshot_26 from "~images/screenshots/s26.jpg";

// Install Swiper modules
SwiperCore.use([Autoplay, Pagination]);

const Screenshots = (props) => {
    return (
        <section id="screenshots" className={props.className}>
            
            {/* <!-- Container --> */}
            <Container>

                {/* <!-- Section title --> */}
				<Row className="justify-content-center">
					<Col className="col-12 col-md-10 col-lg-6">
						
						<div className="section-title text-center">
							<h3>App Screenshots</h3>
							<p>Our app allows you to get through every step with ease and visually communicate with the help of screenshots</p>
						</div>
						
					</Col>
				</Row>         

                <Row>

                    <Col className="col-12">

                        {/* <!-- Carousel --> */}
                        <SRLWrapper>
                            <Swiper
                                className="screenshot-slider zoom-screenshot"
                                autoplay={{
                                    delay: 5000,
                                    disableOnInteraction: false
                                }}
                                pagination={{ clickable: true }}
                                breakpoints={{
                                    320: {
                                        spaceBetween: 15,
                                        slidesPerView: 1
                                    },
                                    768: {
                                        spaceBetween: 20,
                                        slidesPerView: 2
                                    },
                                    1024: {
                                        spaceBetween: 30,
                                        slidesPerView: 4
                                    }
                                }}
                            >

                                <SwiperSlide className="item">
                                    <a href={Screenshot_1}>
                                        <img src={Screenshot_1} alt="" />
                                    </a>
                                </SwiperSlide>

                                <SwiperSlide className="item">
                                    <a href={Screenshot_2}>
                                        <img src={Screenshot_2} alt="" />
                                    </a>
                                </SwiperSlide>

                                <SwiperSlide className="item">
                                    <a href={Screenshot_3}>
                                        <img src={Screenshot_3} alt="" />
                                    </a>
                                </SwiperSlide>

                                <SwiperSlide className="item">
                                    <a href={Screenshot_4}>
                                        <img src={Screenshot_4} alt="" />
                                    </a>
                                </SwiperSlide>

                                <SwiperSlide className="item">
                                    <a href={Screenshot_5}>
                                        <img src={Screenshot_5} alt="" />
                                    </a>
                                </SwiperSlide>

                                <SwiperSlide className="item">
                                    <a href={Screenshot_6}>
                                        <img src={Screenshot_6} alt="" />
                                    </a>
                                </SwiperSlide>

                                <SwiperSlide className="item">
                                    <a href={Screenshot_7}>
                                        <img src={Screenshot_7} alt="" />
                                    </a>
                                </SwiperSlide>

                                <SwiperSlide className="item">
                                    <a href={Screenshot_8}>
                                        <img src={Screenshot_8} alt="" />
                                    </a>
                                </SwiperSlide>
                                
                                <SwiperSlide className="item">
                                    <a href={Screenshot_9}>
                                        <img src={Screenshot_9} alt="" />
                                    </a>
                                </SwiperSlide>

                                <SwiperSlide className="item">
                                    <a href={Screenshot_10}>
                                        <img src={Screenshot_10} alt="" />
                                    </a>
                                </SwiperSlide>

                                <SwiperSlide className="item">
                                    <a href={Screenshot_11}>
                                        <img src={Screenshot_11} alt="" />
                                    </a>
                                </SwiperSlide>

                                <SwiperSlide className="item">
                                    <a href={Screenshot_12}>
                                        <img src={Screenshot_12} alt="" />
                                    </a>
                                </SwiperSlide>

                                <SwiperSlide className="item">
                                    <a href={Screenshot_13}>
                                        <img src={Screenshot_13} alt="" />
                                    </a>
                                </SwiperSlide>

                                <SwiperSlide className="item">
                                    <a href={Screenshot_14}>
                                        <img src={Screenshot_14} alt="" />
                                    </a>
                                </SwiperSlide>

                                <SwiperSlide className="item">
                                    <a href={Screenshot_15}>
                                        <img src={Screenshot_15} alt="" />
                                    </a>
                                </SwiperSlide>

                                <SwiperSlide className="item">
                                    <a href={Screenshot_16}>
                                        <img src={Screenshot_16} alt="" />
                                    </a>
                                </SwiperSlide>

                                <SwiperSlide className="item">
                                    <a href={Screenshot_17}>
                                        <img src={Screenshot_17} alt="" />
                                    </a>
                                </SwiperSlide>

                                <SwiperSlide className="item">
                                    <a href={Screenshot_18}>
                                        <img src={Screenshot_18} alt="" />
                                    </a>
                                </SwiperSlide>

                                <SwiperSlide className="item">
                                    <a href={Screenshot_19}>
                                        <img src={Screenshot_19} alt="" />
                                    </a>
                                </SwiperSlide>

                                <SwiperSlide className="item">
                                    <a href={Screenshot_20}>
                                        <img src={Screenshot_20} alt="" />
                                    </a>
                                </SwiperSlide>

                                <SwiperSlide className="item">
                                    <a href={Screenshot_21}>
                                        <img src={Screenshot_21} alt="" />
                                    </a>
                                </SwiperSlide>

                                <SwiperSlide className="item">
                                    <a href={Screenshot_22}>
                                        <img src={Screenshot_22} alt="" />
                                    </a>
                                </SwiperSlide>

                                <SwiperSlide className="item">
                                    <a href={Screenshot_23}>
                                        <img src={Screenshot_23} alt="" />
                                    </a>
                                </SwiperSlide>

                                <SwiperSlide className="item">
                                    <a href={Screenshot_24}>
                                        <img src={Screenshot_24} alt="" />
                                    </a>
                                </SwiperSlide>
                                
                                <SwiperSlide className="item">
                                    <a href={Screenshot_25}>
                                        <img src={Screenshot_25} alt="" />
                                    </a>
                                </SwiperSlide>

                                <SwiperSlide className="item">
                                    <a href={Screenshot_26}>
                                        <img src={Screenshot_26} alt="" />
                                    </a>
                                </SwiperSlide>

                                

                            </Swiper>
                        </SRLWrapper>

                    </Col>

                </Row>
                    
            </Container>

        </section>
    );
}

export default Screenshots;