import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

// Images
import AwesomeFeatures from "~images/home/feature page.png";

const Features = (props) => {
    return (
        <section id="features" className={props.className}>
			
			{/* <!-- Container --> */}
			<Container>
				
				{/* <!-- Section title --> */}
				<Row className="justify-content-center">
					<Col className="col-12 col-md-10 col-lg-6">
						
						<div className="section-title text-center">
							<h3>Awesome Features</h3>
							<p>The features of EatCo app is what makes it the best! We aim to be a community that spreads the regional delicacies outside the boundaries of our own home

</p>
						</div>
						
					</Col>
				</Row>
			
				<Row className="d-flex align-items-center">
					
					{/* <!-- Left --> */}
					<Col className="col-12 col-md-6 col-lg-4"> 				
						
						<ul className="features-item">
							
							{/* <!-- Feature box --> */}
							<li> 
								<div className="feature-box  d-flex"> 
									
									
									{/* <!-- Box Text --> */}
									<div className="box-text customalign  align-self-center align-self-md-start">
										<h4>Features of customers  </h4>
										
									</div>
									
								</div>
							</li>
							
							<li> 
								<div className="feature-box d-flex"> 
									
									{/* <!-- Box icon --> */}
									<div className="box-icon">
										<div className="icon icon-basic-gear"></div>
									</div>
									
									{/* <!-- Box Text --> */}
									<div className="box-text hai align-self-center align-self-md-start">
										<h4 >Variety of dishes</h4>
										<p>you can choose a variety of food and get delightful taste from north to south Indian cuisine as per your choices. </p>
									</div>
									
								</div>
							</li>
							
							{/* <!-- Feature box --> */}
							<li> 
								<div className="feature-box d-flex"> 
									
									{/* <!-- Box icon --> */}
									<div className="box-icon">
										<div className="icon icon-basic-lock"></div>
									</div>
									
									{/* <!-- Box Text --> */}
									<div className="box-text hai align-self-center align-self-md-start">
										<h4 >Time Slot</h4>
										<p> you can specify the time when the order is to be prepared and you can place your order 4 hours before</p>
									</div>
									
								</div>
							</li>
							
							{/* <!-- Feature box --> */}
							<li> 
								<div className="feature-box d-flex"> 
									
									{/* <!-- Box icon --> */}
									<div className="box-icon">
										<div className="icon icon-basic-message-txt"></div>
									</div>
									
									{/* <!-- Box Text --> */}
									<div className="box-text hai align-self-center align-self-md-start">
										<h4>Homely food nearby </h4>
										<p>you can order your favourite food, a home chef of your choice in your locality </p>
									</div>
									
								</div>
							</li>
							
						</ul>
					</Col>
					
					{/* <!-- Center --> */}
					<Col className=" col-12 col-lg-4 d-none d-lg-block"> 
						<div className="features-thumb text-center">
							<img src={AwesomeFeatures} alt="" />
						</div>
					</Col>
					
					{/* <!-- Right --> */}
					<Col className="col-12 col-md-6 col-lg-4"> 
						
						<ul className="features-item">
							
							{/* <!-- Feature box --> */}
							<li> 
								<div className="feature-box  d-flex"> 
									
									
									{/* <!-- Box Text --> */}
									<div className="box-text customalign  align-self-center align-self-md-start">
										<h4>Features of Partners  </h4>
										
									</div>
									
								</div>
							</li>
							<li> 
								<div className="feature-box d-flex"> 
									
									{/* <!-- Box icon --> */}
									<div className="box-icon">
										<div className="icon icon-basic-share"></div>
									</div>
									
									{/* <!-- Box Text --> */}
									<div className="box-text hai align-self-center align-self-md-start">
										<h4>Create Menu</h4>
										<p>Create your own menu with fresh new recipes and keep your customers engaged by showcasing your creativity.</p>
									</div>
									
								</div>
							</li>
							
							{/* <!-- Feature box --> */}
							<li> 
								<div className="feature-box d-flex"> 
									
									{/* <!-- Box icon --> */}
									<div className="box-icon">
										<div className="icon icon-basic-sheet-multiple"></div>
									</div>
									
									{/* <!-- Box Text --> */}
									<div className="box-text hai align-self-center align-self-md-start">
										<h4>Partners report </h4>
										<p>Get acknowledge of your monthly report and have a close look at your completed and rejected orders.  </p>
									</div>
									
								</div>
							</li>
							
							{/* <!-- Feature box --> */}
							<li> 
								<div className="feature-box d-flex"> 
									
									{/* <!-- Box icon --> */}
									<div className="box-icon">
										<div className="icon icon-basic-alarm"></div>
									</div>
									
									{/* <!-- Box Text --> */}
									<div className="box-text hai align-self-center align-self-md-start">
										<h4>Kitchen ON/OFF</h4>
										<p>As per your ease, you have an option to turn your kitchen ON/OFF  </p>
									</div>
									
								</div>
							</li>
							
						</ul>
					</Col>
					
				</Row>
				
			</Container>
			
		</section>
    );
}

export default Features;