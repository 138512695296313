import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

// Images
import TrackTime from "~images/home/track.png";
import DailySchedule from "~images/home/daily.png";

const Overview = (props) => {
    return (
        <section id="overview" className={props.className}>
			
			{/* <!-- Container --> */}
			<Container>
				
				{/* <!-- Track time --> */}
				<Row>
					
					{/* <!-- Content --> */}
					<Col className="col-12 col-lg-6 offset-lg-1 order-lg-last res-margin">
							
						{/* <!-- Section title --> */}
						<div className="section-title text-center text-lg-start">
							<h3>How to become an EatCo Partner  </h3>
							{/* <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. </p> */}
						</div>

						{/* <!-- Items --> */}
						<div className="overview-item">

							{/* <!-- Item 1 --> */}
							<div className="overview-box d-flex flex-wrap">

								{/* <!-- Icon --> */}
								<div className="icon icon-basic-compass"></div>

								{/* <!-- Content --> */}
								<div className="content">
									{/* <h6 className="font-weight-bold mb-2 mt-0">  </h6> */}
									<p className='smallp'>Sign up and register with your uploaded KYC documents.</p>
								</div>

							</div>

							{/* <!-- Item 2 --> */}
							<div className="overview-box d-flex flex-wrap">

								{/* <!-- Icon --> */}
								<div className="icon icon-basic-helm"></div>

								{/* <!-- Content --> */}
								<div className="content">
									{/* <h6 className="font-weight-bold mb-2 mt-0"></h6> */}
									<p>EatCo team will be sending a sales executive to visit your place and do verification. Once the approval is done, the team will sign an agreement.</p>
								</div>

							</div>

							{/* <!-- Item 3 --> */}
							<div className="overview-box d-flex flex-wrap">

								{/* <!-- Icon --> */}
								<div className="icon icon-basic-link"></div>

								{/* <!-- Content --> */}
								<div className="content">
									{/* <h6 className="font-weight-bold mb-2 mt-0">     </h6> */}
									<p className='smallp'>You are all set to list your menu and make money.</p>
								</div>

							</div>

						</div>
							
					</Col>
					
					{/* <!-- Image --> */}
					<Col className="col-12 col-lg-5 order-lg-first text-sm-center">
						<img src={TrackTime} alt="" />
					</Col>
					
				</Row>
				
				<div className="empty-100"></div>
				
				{/* <!-- Daily schedule --> */}
				<Row>
					
					{/* <!-- Content --> */}
					<Col className="col-12 col-lg-6 res-margin">
							
						{/* <!-- Section title --> */}
						<div className="section-title text-center text-lg-start">
							<h3>How it benefits the customers  </h3>
							<p>We offer delicious homemade dishes made with love, passion and your safety are our top priority </p>
						</div>

						{/* <!-- List --> */}
						<ul className="overview-list">

							<li>
								<p><i className="fa-li fas fa-check"></i>    Explore authentic cuisines from your local community and unlock a treasure of a new blend of flavor and taste </p>
							</li>

							<li>
								<p><i className="fa-li fas fa-check"></i>     A good way to take care of your health is to eat homely food every day. </p>
							</li>

							<li>
								<p><i className="fa-li fas fa-check"></i>     Homemade food is usually prepared with natural ingredients compared to commercially prepared food. </p>
							</li>

							<li>
								<p><i className="fa-li fas fa-check"></i>     You get to eat clean meals containing vital nutrients like vitamins and minerals. </p>
							</li>

							<li>
								<p><i className="fa-li fas fa-check"></i>     It is convenient and affordable to get homemade food. </p>
							</li>

						</ul>

						{/* <!-- Button --> */}
						{/* <p className="text-center text-lg-start">
							<a href="/" className="btn">Learn More</a>
						</p> */}
					
					</Col>
					
					{/* <!-- Image --> */}
					<Col className="imgg col-12 col-lg-5 offset-lg-1 text-sm-center">
						<img src={DailySchedule} alt="" />
					</Col>
					
				</Row>
				
			</Container>
			
		</section>
    );
}

export default Overview;